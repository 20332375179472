import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";

import { NavbarLinks, NavbarSideLinksPrivate } from "helpers/constants";

import NavbarItem from "./navbar-item/NavbarItem";

import classes from "./NavbarList.module.css";
import { Badge, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import useWindowWidth from "helpers/useWindowWidth";
import { languageActions } from "store-redux/language-slice";
import { useTranslation } from "react-i18next";
import Button from "components/UI/button/Button";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;



const NavbarList = ({
  className,
  onClose,
  isSide,
  isSearchOpen,
  languageValues,
  filterDataChange,
  categoryData,
  filterData,
  eventData,
  NAVBAR_LINKS,
  isAuthLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log("isLoggedIn", isLoggedIn, user);
  const { language } = useSelector((state) => state.language);

  const [myAccountModalOpen, setmyAccountModalOpen] = useState(false);
  const filters = useSelector((state) => state.filter);
  console.log("filter data in navbar", filterData);
  const cart = useSelector((state) => state.cart);
  const wishlist = useSelector((state) => state.wishlist);
  const [selectedAddress, setSelectedAddress] = useState();
  const [extractedValues, setExtractedValues] = useState({});
  console.log("selectedAddress", selectedAddress);
  const [searchTerm, setSearchTerm] = useState("");

  const width = useWindowWidth();
  const location = useLocation();

  const isSearchpage = window.location.pathname.includes("/search");
  console.log("is search page", isSearchpage);

  useEffect(() => {
    if (filters) {
      setSelectedAddress(filters?.address);
    }
  }, [filters]);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const setMyAccountModalHandler = () => {
    setmyAccountModalOpen((value) => !value);
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    dispatch(modalActions.toggleModal());
    navigate("/");
  };

  let navList = null;
  if (NAVBAR_LINKS[language]) {
    navList = NAVBAR_LINKS.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        isMenu={link.type == "menu"}
        menuList={link.menu}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        <div className={classes.linkFlex}>
          {link.title}
          {link.type == "menu" && (
            <img
              src="/news-img/icons/dropdown-black.svg"
              alt="arrow"
              className={classes.blackArrow}
            />
          )}
        </div>
      </NavbarItem>
    ));
  } else {
    navList = NAVBAR_LINKS.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        isMenu={link.type == "menu"}
        menuList={link.menu}
        key={link.key ? link.key : link.to + index}
        // pageLink={link.to}
        pageLink={link.to}
        title={link?.title}
      >
        <div className={classes.linkFlex}>
          {link.title}
          {link.type == "menu" && (
            <img
              src="/news-img/icons/dropdown-black.svg"
              alt="arrow"
              className={classes.blackArrow}
            />
          )}
        </div>
      </NavbarItem>
    ));
  }

  const placeholderStyle = {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingLeft: "8px",
  };

  const customArrow = (
    <img
      src="/news-img/icons/navbar-droparrow.svg"
      alt="arrow"
      style={{
        width: "15px",
        height: "7.5px",
      }}
    /> // Customize the arrow color as needed
  );
  const placeholderIcon = (
    <img
      src="/news-img/icons/language.svg"
      alt="arrow"
      style={{
        width: "24px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
      }}
    />
  );

  useEffect(() => {
    // Extract values using URLSearchParams
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const values = {};

    params.forEach((value, key) => {
      if (key == "latitude" || key == "longitude" || key == "range") {
        values[key] = Number(value);
      } else {
        values[key] = value;
      }
    });

    // Update state with extracted values
    setExtractedValues(values);
  }, [location.search]);

  console.log("extracted values in navbar", extractedValues);

  const languageChangeHandler = (lang) => {
    console.log("lang selected", lang);
    dispatch(languageActions.toggleLanguage({ language: lang }));
  };

  const openSignInModal = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: `signin`,
        // modalType: `vehicleimageview${InventoryNo}`
      })
    );
  };

  const params = new URLSearchParams(window.location.search);
  const search = params.get("search");

  useEffect(() => {
    //initial page loading adding the default page and limit values to url
    if (searchTerm != "") {
      //on going to articles,or historical articles page,if there is searchterm in navbar.navigate
      params.set("search", searchTerm);
      if (location.pathname.includes("advanced-search")) {
        //only when pathnmae includes advanced search
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    const search = params.get("search");
    if (search) {
      setSearchTerm(search); //if there is search in url,set it to search term
    } else {
      setSearchTerm("");
    }
  }, [location.search]);

  const handlePressEnter = () => {
    navigate(`/advanced-search?search=${searchTerm}&page=1&limit=10`);
  };

  return (
    <div className={classes.navbarListContainer}>
      <div className={classes.group}>
        <ul className={`${classes.navbarList} ${className}`}>
          {!isSearchOpen && <div className={classes.pages}>{navList}</div>}
        </ul>
        <Select
          className="navbar-select"
          popupClassName="navbar-select-dropdown"
          value={language}
          onChange={(value) => languageChangeHandler(value)}
          suffixIcon={customArrow}
          placeholder={placeholderIcon}
          // open={true}
          // placeholder={
          //   <LanguageIcon className="navbar-select-placeholder-icon" />
          // }
        >
          {languageValues?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      <div className={classes.group}>
        {/* {!window.location.pathname.includes("/advanced-search") && ( */}
        <Input
          className="navbar-input"
          placeholder={t("search_here")}
          suffix={
            <SearchOutlined
              onClick={handlePressEnter}
              className={classes.searchOutline}
            />
          }
          onChange={(e) => setSearchTerm(e.target.value)}
          onPressEnter={handlePressEnter}
          value={searchTerm}
        />
        {/* )} */}
        <div>
          {!isLoggedIn && !isAuthLoading && (
            <Button className={classes.loginButton} onClick={openSignInModal}>
              {t("login")}
            </Button>
          )}
          {!isLoggedIn && isAuthLoading && (
            <LoadingOutlined style={{ width: "18px", marginRight: "5px" }} />
          )}
          {isLoggedIn && !isAuthLoading && (
            <div className={classes.circularDiv}>
              <img
                src="/news-img/profile.svg"
                alt="profile"
                className={classes.profilePic}
                onClick={() => navigate("/my-account")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavbarList;
